import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { MdClose, MdWarningAmber } from "react-icons/md";
import { Button } from "naffles-design-system";

export const ConfirmationModal = ({
    callback, status 
}) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function callFunction() {
    callback();
    closeModal();
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Button
        label="unstake"
        variant="secondary"
        width="span"
        weight="base"
        status={status}
        onClick={openModal}
      />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-nafl-charcoal-700 !bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-nafl-charcoal-800 text-left align-middle shadow-xl transition-all outline outline-2 outline-offset-2 outline-nafl-charcoal-400">
                  <Dialog.Title
                    as="h3"
                    className="px-6 pt-3 pb-0 text-[1.5rem] text-nafl-charcoal-100 text-center"
                  >
                    <div className="flex items-center space-x-4 m-auto text-nafl-sponge-500">
                      <MdWarningAmber className="text-[2rem]" />{" "}
                      <span>WARNING!</span>
                    </div>
                    <button onClick={closeModal}>
                      <MdClose className="text-nafl-charcoal-100 absolute top-2 right-2 text-title-2xl" />
                    </button>
                  </Dialog.Title>
                  <div className="text-nafl-charcoal-100 p-6 pt-0">
                    <p className="pb-4">
                      You will lose ALL your unclaimed rewards, including any
                      bonuses
                    </p>
                    <p>ARE YOU SURE YOU WANT TO CONTINUE?</p>
                    <div className="flex space-x-4 place-content-end pt-10">
                      <div className="border-2 border-nafl-sponge-500 rounded-md">
                        <Button
                          label="continue awyway"
                          variant="tertiary"
                          width="inherit"
                          weight="base"
                          onClick={() => callFunction()}
                        />
                      </div>

                      <Button
                        label="cancel"
                        variant="primary"
                        width="inherit"
                        weight="base"
                        onClick={closeModal}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
