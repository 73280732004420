import { PropsWithChildren, ReactNode, useState } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { Listbox } from "@headlessui/react";
import {MdOutlineUnfoldMore, MdCheck } from "react-icons/md"

interface Props {
  formID: ReactNode | any;
  label: ReactNode;
  selectedValue?: any;
  dropdownSelected: (value?: any) => void;
  object?: any;
}

const InputStyles = cva("", {
  variants: {
    width: {
      span: "w-full",
      default: "w-56",
    },
  },
});

interface Props extends VariantProps<typeof InputStyles> {}

interface Input extends PropsWithChildren {}

export const Dropdown = ({ formID, object, width, label, selectedValue, dropdownSelected, ...props }: Props) => {
  const [selectedPerson, setSelectedPerson] = useState(object[0]);
  
  function changeValue(value: any) {
    console.log(value)
    setSelectedPerson(value);
    dropdownSelected(value);
  }

  return (
    <div className={InputStyles({ width })}>
      <div className="flex flex-col space-y-2" {...props}>
        <label className="text-body-xs text-nafl-sponge-500">{label}</label>
        <Listbox value={selectedPerson} onChange={changeValue}>
          <Listbox.Button className="flex grow bg-nafl-charcoal-600 text-body-base text-nafl-charcoal-100 border-[1px] rounded-lg p-2 bg-nafl-charcoal-600 border-nafl-sponge-500 focus:outline-dashed focus:outline-2 focus:outline-offset-2 focus:outline-nafl-sys-complete placeholder:text-nafl-charcoal-400 placeholder:text-body-base text-left">
            <span className="grow truncate">{selectedPerson.item}</span>
            <MdOutlineUnfoldMore className="text-nafl-sponge-500 text-body-xl"/>
          </Listbox.Button>
          <Listbox.Options className="flex flex-col overflow-hidden bg-nafl-charcoal-700 rounded-lg shadow-lg focus:outline-dashed focus:outline-2 focus:outline-offset-2 focus:outline-nafl-sys-complete">
            {object.map((listitem: any) => (
              <Listbox.Option
                className={({ active }) =>
                  `relative cursor-pointer select-none p-2 pl-10 border-b-[1px] border-nafl-charcoal-500 last:border-none transition-all duration-300 text-body-base  ${
                    active
                      ? "bg-nafl-sponge-700 text-nafl-charcoal-800 "
                      : "text-nafl-charcoal-300"
                  }`
                }
                key={listitem.id}
                value={listitem}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {listitem.item}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                       
                        <MdCheck className="text-nafl-sponge-500 text-body-xl"/>
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
    </div>
  );
};
