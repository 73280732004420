import { ReactNode, HTMLAttributes, useState, useEffect } from "react";
import { Button, Dropdown } from "naffles-design-system";
import { TfiUnlock, TfiLock } from "react-icons/tfi";
import { FaArrowRight, FaTwitter } from "react-icons/fa";
import { useStake } from "../../hooks/useStake";
import { useUnstake } from "../../hooks/useUnstake";
import StakeImg from "../../static/omnipotent.png";
import { useWaitForStakeTransaction } from "../../hooks/useWaitForStakeTransaction";
import { useWaitForUnstakeTransaction } from "../../hooks/useWaitForUnstakeTranscation";
import { showError, showSuccess, showSuccessUnstake } from "../../lib/toast";
import { ConfirmationModal } from "../modal";

const tweetLink =
  "https://twitter.com/intent/tweet?text=I%20just%20degen%E2%80%99d%20into%20%40NafflesOfficial%20and%20staked%20my%20Omnipotent%20Key%2C%20because%3A%0A%0A%E2%80%A2%20Only%20350%20NFT%20holders%20can%20run%20NFT%20raffles%0A%E2%80%A2%20More%20profits%20from%20higher%20platform%20discounts%0A%E2%80%A2%20Free%20tickets%20to%20win%20blue%20chip%20NFTs%0A%0ANever%20sell%20your%20%23NFT%20below%20floor%20again%21%20";

const stakingDuration = [
  {
    id: 3,
    item: "12 Months",
    seconds: 31536000,
  },
  {
    id: 2,
    item: "6 Months",
    seconds: 15552000,
  },
  {
    id: 1,
    item: "3 Months",
    seconds: 5184000,
  },
  {
    id: 0,
    item: "1 Month",
    seconds: 2592000,
  },
];

interface Props extends HTMLAttributes<HTMLDivElement> {
  collection: ReactNode;
  id: any;
  stakingContractAddress: `0x${string}`;
  stakingLink: any;
  stakePeriod: any;
  stakeDate: any;
  isStaked: any;
}

export const CardStake = ({
  collection,
  id,
  stakingContractAddress,
  stakingLink,
  stakePeriod,
  stakeDate,
  isStaked,
  ...props
}: Props) => {
  const [isStakedState, setIsStaked] = useState(isStaked);
  const [stakeDateState, setStakeDate] = useState(stakeDate);
  const [stakedUntil, setStakedUntil] = useState("");
  const [stakePeriodState, setStakePeriod] = useState(stakePeriod);
  const [data, isLoading, , write] = !isStaked
    ? useStake(id, stakePeriodState)
    : [null, false, false, null];

  const [unstakeData, unstakeIsLoading, unstakeError, unstakeWrite] = isStaked
    ? useUnstake(stakingContractAddress, id)
    : [null, false, null];

  const [
    isStakeTransactionLoading,
    isStakeTransactionSuccess,
    isStakeTransactionError,
  ] = useWaitForStakeTransaction(data?.hash, showSuccess, showError);

  const [
    isUnstakeTransactionLoading,
    isUnstakeTransactionSuccess,
    isUnstakeTransactionError,
  ] = useWaitForUnstakeTransaction(
    unstakeData?.hash,
    showSuccessUnstake,
    showError
  );

  const btnHandler = () => {
    if (!isStakedState && !isLoading) {
      write?.();
    }
    if (isStaked && !unstakeIsLoading) {
      unstakeWrite?.();
    }
  };

  const unstake = () => {
    if (isStaked && !unstakeIsLoading) {
        unstakeWrite?.();
    }
  }

  useEffect(() => {
    if (isStakeTransactionSuccess) {
      setIsStaked(true);
      setStakeDate(Date.now() / 1000);
    }
  }, [isStakeTransactionSuccess]);

  useEffect(() => {
    if (isUnstakeTransactionSuccess) {
      setIsStaked(false);
      setStakeDate(null);
    }
  }, [isUnstakeTransactionSuccess]);

  useEffect(() => {
    getStakedUntil();
  }, [stakePeriodState, stakeDateState]);

  function getStakedDurationForStakedPeriod(stakePeriod: number) {
    const reversedList = [...stakingDuration].reverse();
    return reversedList[stakePeriod];
  }

  const getStakeduntilTimestamp = () => {
    return (
      stakeDateState +
      getStakedDurationForStakedPeriod(stakePeriodState).seconds
    );
  };

  const getStakedUntil = () => {
    const timestamp = getStakeduntilTimestamp();
    const date = moment.unix(timestamp).format("DD MMM YYYY HH:mm");
    setStakedUntil(date);
  };

  const stakedPeriodIsOver = () => {
    const lockedUntil = getStakeduntilTimestamp();
    if (lockedUntil < Date.now() / 1000) {
      return true;
    }
    return false;
  };

  const moment = require("moment-timezone");
  const setStakePeriodAfterSelection = (value: any) => {
    setStakePeriod(value.id);
  };

  const getButtonStatus = () => {
    if (isStakeTransactionLoading || isUnstakeTransactionLoading) {
      return "loading";
    }
    if (isStakedState) {
      if (stakedPeriodIsOver()) {
        return "enabled";
      }
      return "disabled";
    }
  };
  return (
    <div
      className={`relative h-[28rem] w-80 max-w-[20rem] rounded-3xl  shadow-xl before:content[''] before:absolute before:h-8 before:w-8 before:bg-nafl-charcoal-600 before:rounded-full before:top-[-1rem] before:left-[calc(50%-1rem)] ${
        !isStakedState ? "bg-nafl-charcoal-700" : "bg-nafl-sponge-500"
      }`}
      {...props}
    >
      <div
        className={`p-4 pt-8 h-24 flex w-full rounded-3xl ${
          !isStakedState ? "bg-nafl-charcoal-800" : "bg-nafl-sponge-600"
        }`}
      >
        <div className="grow">
          <p
            className={` text-bold-lg leading-5 uppercase ${
              !isStakedState
                ? "text-nafl-charcoal-100"
                : "text-nafl-charcoal-800"
            }`}
          >
            {collection}
          </p>
          <span
            className={` text-body-md leading-5  ${
              !isStakedState
                ? "text-nafl-charcoal-100"
                : "text-nafl-charcoal-800"
            }`}
          >
            {id}
          </span>
        </div>
        <div className="h-16 w-16 rounded-xl overflow-hidden mt-[-1rem] border-2 border-nafl-charcoal-200">
          <img height="100%" width="100%" src={StakeImg} />
        </div>
      </div>
      <div className="p-2 mt-2 w-full text-center">
        {!isStakedState ? (
          <>
            <TfiUnlock className="text-[2.5rem] mx-auto mb-4 text-nafl-charcoal-100 mb-2" />
            <span className="text-nafl-charcoal-100 uppercase text-bold-lg">
              unstaked
            </span>
          </>
        ) : (
          <>
            {stakedPeriodIsOver() ? (
              <TfiUnlock className="text-[2.5rem] text-nafl-charcoal-800 mx-auto  mb-2" />
            ) : (
              <TfiLock className="text-[2.5rem] text-nafl-charcoal-800 mx-auto  mb-2" />
            )}

            <span className="text-nafl-charcoal-800 uppercase text-bold-lg">
              staked {getStakedDurationForStakedPeriod(stakePeriodState).item}
            </span>
          </>
        )}
      </div>
      <div className="flex flex-col space-y-4 px-4">
        <div className="z-50">
          {!isStakedState ? (
            <>
              <Dropdown
                formID="dropdown"
                label="Select Staking Duration"
                dropdownSelected={setStakePeriodAfterSelection}
                object={stakingDuration}
                width="span"
              />
            </>
          ) : (
            <>
              <div className="w-full bg-nafl-sponge-600 rounded-xl p-3 shadow-inner uppercase text-center text-bold-sm">
                {stakedPeriodIsOver() ? "unlocked" : "unlocks: " + stakedUntil}
              </div>
              <div className=" w-full text-center m-auto py-4 text-nafl-jade-700 uppercase text-bold-base">
                <a
                  href={tweetLink}
                  target="_blank"
                  className="relative items-center hover:text-nafl-sponge-700"
                >
                  <FaTwitter className="text-body-xl inline-block " />
                  <span className="pl-4">Tweet about it!</span>
                </a>
              </div>
              <hr className="my-0 border-nafl-charcoal-500" />
            </>
          )}
        </div>
        <div className="h-18 absolute bottom-6 w-[18rem]">
          <div
            className={`cursor-pointer uppercase pb-4 ${
              !isStakedState
                ? "text-nafl-charcoal-200"
                : "text-nafl-charcoal-700"
            }`}
          >
            <a
              href={stakingLink}
              target="_blank"
              className="flex items-center space-x-2"
            >
              <span>View Staking Details</span>
              <FaArrowRight
                className={
                  !isStakedState
                    ? "text-nafl-sponge-500"
                    : "text-nafl-charcoal-700"
                }
              />
            </a>
          </div>
          {!isStakedState ? (
            <Button
              label="Stake"
              onClick={btnHandler}
              status={getButtonStatus()}
              size="base"
              variant="primary"
              width="span"
            />
          ) : (
            <ConfirmationModal callback={() => unstake()} status={getButtonStatus()} />
          )}
        </div>
      </div>
    </div>
  );
};
