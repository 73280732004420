import { useState, useEffect } from "react";
import { useWaitForTransaction } from "wagmi";
import * as Sentry from "@sentry/react";

export function useWaitForUnstakeTransaction(
  hash: string,
  showSuccess: () => void,
  showError: () => void
) {
  const [isUnstakeTransactionSuccess, setIsUnstakeTransactionSuccess] =
    useState(false);
  const {
    isLoading: isUnstakeTransactionLoading,
    isError: isUnstakeTransactionError,
  } = useWaitForTransaction({
    hash,
    onError(error: Error) {
      showError();
      Sentry.captureException(error);
    },
    onSuccess(data) {
      if (data.status == 1) {
        showSuccess();
        setIsUnstakeTransactionSuccess(true);
      } else {
        showSuccess();
        Sentry.captureMessage(
          "Unstake transaction failed with logs " + data.logs
        );
      }
    },
  });

  return [
    isUnstakeTransactionLoading,
    isUnstakeTransactionSuccess,
    isUnstakeTransactionError,
  ];
}
