import { useState, useEffect } from "react";
import { useWaitForTransaction } from "wagmi";
import * as Sentry from "@sentry/react";

export function useWaitForStakeTransaction(
  hash: string,
  showSuccess: () => void,
  showError: () => void
) {
  const [isStakeTransactionSuccess, setIsStakeTransactionSuccess] =
    useState(false);
  const {
    isLoading: isStakeTransactionLoading,
    isError: isStakeTransactionError,
  } = useWaitForTransaction({
    hash,
    onError(error: Error) {
      showError();
      Sentry.captureException(error);
    },
    onSuccess(data) {
      if (data.status == 1) {
        showSuccess();
        setIsStakeTransactionSuccess(true);
      } else {
        showSuccess();
        Sentry.captureMessage(
          "Staking transaction failed with logs " + data.logs
        );
      }
    },
  });

  return [
    isStakeTransactionLoading,
    isStakeTransactionSuccess,
    isStakeTransactionError,
  ];
}
