import { usePrepareContractWrite, useContractWrite } from "wagmi";
import staking from "../../contracts/FoundersKeyStaking.json";

export function useUnstake(address: `0x${string}`, id: number) {
  const { config } = usePrepareContractWrite({
    address,
    abi: staking.abi,
    functionName: "unstake",
    args: [id],
  });
  const { data, isLoading, isError, write } = useContractWrite(config);
  return [data, isLoading, isError, write];
}
