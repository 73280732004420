import { HTMLAttributes, ReactNode } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { PropsWithChildren } from "react";
import maskURL from "../assets/wide-mask.png";
interface Props extends HTMLAttributes<HTMLDivElement> {
  imageURL: ReactNode | any;
}
const maskStyles = cva("mx-auto", {
  variants: {
    size: {
      sm: "max-w-[8rem]",
      base: "max-w-[10rem]",
      lg: "max-w-[15rem]",
      xl: "max-w-[30rem]",
    },
  },
});
interface Props extends VariantProps<typeof maskStyles> {}
interface ImageWideMask extends PropsWithChildren {}
export const ImageWideMask = ({ imageURL, size, ...props }: Props) => {
  return (
    <div className={maskStyles({ size })}>
      <div
        className="bg-cover bg-center max-w-[100%] pb-[35.5%]"
        style={{
          backgroundImage: `url(${imageURL})`,
          WebkitMaskImage: `url(${maskURL})`,
          maskImage: `url(${maskURL})`,
          WebkitMaskSize: "cover",
          maskSize: "cover",
        }}
        {...props}
      />
    </div>
  );
};
