import { usePrepareContractWrite, useContractWrite } from "wagmi";
import staking from "../../contracts/FoundersKeyStaking.json";

export function useStake(id: number, staking_period: number) {
  const address = process.env.REACT_STAKING_CONTRACT_ADDRESS || "";
  const { config } = usePrepareContractWrite({
    address,
    abi: staking.abi,
    functionName: "stake",
    args: [id, staking_period],
  });
  const { data, isLoading, isError, write } = useContractWrite(config);
  return [data, isLoading, isError, write];
}
