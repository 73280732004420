import { useState, useEffect } from "react";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { useAccount } from "wagmi";
import * as Sentry from "@sentry/react";

const getChain = () => {
  if (process.env.REACT_NETWORK === "goerli") {
    return EvmChain.GOERLI;
  }
  return EvmChain.ETHEREUM;
};

export function useGetAddressNFTs() {
  const { address } = useAccount();
  const [nfts, setNfts] = useState();
  const chain = getChain();
  const [synced, setSynced] = useState(false);
  const contractAddress = process.env.REACT_FOUNDERS_KEY_ADDRESS || "";
  const [moralisInitialised, setMoralisInitialised] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function startMoralis() {
    if (!moralisInitialised) {
      await Moralis.start({ apiKey: process.env.REACT_MORALIS_API_KEY })
        .then(() => {
          setMoralisInitialised(true);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  }

  useEffect(() => {
    startMoralis();
  }, []);

  async function getNFTs() {
    return await Moralis.EvmApi.nft
      .getWalletNFTs({
        address: address,
        tokenAddresses: [contractAddress],
        chain,
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }

  async function syncCollection() {
    return await Moralis.EvmApi.nft
      .syncNFTContract({
        address: contractAddress,
        chain,
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    if (!moralisInitialised) return;

    getNFTs().then((response) => {
      if (!response) return;
      const jsonResponse = response.toJSON();
      setNfts(jsonResponse["result"]);
      if (jsonResponse["status"] != "SYNCED") {
        syncCollection().then((response) => {
          setIsLoading(false);
          setSynced(true);
        });
      } else {
        setIsLoading(false);
      }
    });
  }, [address, synced, moralisInitialised]);

  return [nfts, isLoading];
}
