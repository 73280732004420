import { FaDiscord, FaTwitter } from "react-icons/fa";
import Naffles from "../static/logo-yellow.png";

const twitter = "https://twitter.com/nafflesofficial";
const discord = "https://discord.gg/naffles";

export const Footer = () => (
  <footer className="bg-nafl-charcoal-700">
    <div className="max-w-screen-xl flex flex-col px-4 lg:px-10 lg:flex-row lg:justify-between items-center space-y-4 mx-auto  py-6">
      <article className="flex flex-col lg:flex-row lg:space-x-8 space-y-4 items-center">
        <span className="relative w-36 h-12">
          <img src={Naffles} alt="Logo" />
        </span>
      </article>
      <article className="flex space-x-4 lg:space-x-10 text-white text-body-xl">
        <a href={twitter} target="_blank">
          <FaTwitter className="text-nafl-sponge-500 cursor-pointer hover:text-nafl-sponge-700 transition-colors ease-out duration-150" />
        </a>
        <a href={discord} target="_blank">
          <FaDiscord className="text-nafl-sponge-500 cursor-pointer hover:text-nafl-sponge-700 transition-colors ease-out duration-150" />
        </a>
      </article>
    </div>
    <div className="bg-nafl-charcoal-800">
      <div className="max-w-screen-xl flex flex-col px-4 lg:px-10 lg:flex-row lg:justify-between items-center space-y-4 mx-auto py-4">
        <article className="flex flex-col lg:flex-row lg:space-x-10 space-y-4 items-center">
          <h3 className="text-body-sm  text-center text-nafl-charcoal-400 lg:pt-2">
            @COPYRIGHT {new Date().getFullYear()} NAFFLES ALL RIGHTS RESERVED.
          </h3>
        </article>
      </div>
    </div>
  </footer>
);
