import { ReactNode } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { PropsWithChildren } from "react";
import SpinnerBlack from "../assets/Dual_Ring-1s-200px.gif";
import SpinnerWhite from "../assets/Dual_Ring-white-1s-200px.gif";

interface Props {
  label: ReactNode;
  onClick?: () => void;
}

const btnStyles = cva(
  "px-4 py-2 rounded-md font-roboto-body uppercase font-bold text-nafl-charcoal-800 selection:transition-all ease-out duration-300 cursor-pointer hover:opacity-70",
  {
    variants: {
      variant: {
        primary: "bg-nafl-sponge-500",
        secondary: "bg-nafl-jade-500 text-nafl-charcoal-100 ",
        tertiary: "bg-nafl-charcoal-800 text-nafl-charcoal-100 ",
        special: "bg-gradient-to-r from-nafl-jade-500 to-nafl-sponge-500",
      },
      size: {
        sm: "text-body-sm",
        base: "text-body-base",
        lg: "text-body-lg",
        xl: "text-body-xl",
      },
      width: {
        span: "w-full",
        inherit: "",
      },
      status: {
        default:"",
        loading:"pointer-events-none",
        disabled:"opacity-50 text-nafl-charcoal-300 pointer-events-none",
      },
    },
  }
);
interface Props extends VariantProps<typeof btnStyles> {}

interface Button extends PropsWithChildren {}

export const Button = ({ variant, label, size, width, status, ...props }: Props) => {
  let spinner:boolean;
  let spinnerColour:any;
  if(status === "loading"){
    spinner=true;
  } else {
    spinner=false;
  }
  switch(variant) {
    case "primary":
      spinnerColour=SpinnerBlack
      break;
    case "secondary":
      spinnerColour=SpinnerWhite
      break;
    case "tertiary":
      spinnerColour=SpinnerWhite
      break;
    case "special":
      spinnerColour=SpinnerBlack
      break;
  }
  return (
    <button
      type="button"
      className={btnStyles({ variant: variant, width, status, size })}   
      {...props}
    >
      { spinner ? (
        <span className="flex space-x-2 items-center animate-pulse">
          <img className="w-5 h-5" src={spinnerColour} />
          <span>{label}...</span>
        </span>     
      ):(
        <span>{label}</span>
      )}
     
     
    </button>
  );
};
