import { useEffect } from "react";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import Content from "./components/Content";

import { WagmiConfig, createClient, configureChains, useAccount } from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import { InjectedConnector } from "wagmi/connectors/injected";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://ec95a60e17474f7a903c6a33f6a38e27@o4504106369351680.ingest.sentry.io/4504509769777152",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.2,
});

const getChain = (network: string) => {
  if (network === "goerli") {
    return goerli;
  }
  return mainnet;
};

const network: string = process.env.REACT_NETWORK || "mainnet";
const apiKey: string = process.env.REACT_INFURA_API_KEY || "";

const { chains, provider } = configureChains(
  [getChain(network)],
  [infuraProvider({ apiKey: apiKey }), publicProvider()]
);

const client = createClient({
  connectors: [new InjectedConnector({ chains })],
  autoConnect: true,
  provider: provider,
});

function App() {
  const { address } = useAccount();

  useEffect(() => {
    if (address) {
      Sentry.setUser({ address: address });
    }
  }, [address]);

  return (
    <WagmiConfig client={client}>
      <div className="bg-nafl-charcoal-600 h-screen w-screen flex flex-col overflow-x-hidden">
        <Toaster position="bottom-right" reverseOrder={false} />
        <Header />
        <main className="grow pt-32 pb-32 w-full max-w-screen-xl mx-auto">
          <Content />
        </main>
        <Footer />
      </div>
    </WagmiConfig>
  );
}

export default Sentry.withProfiler(App);
