interface Props {
  label?: any;
  linked?: true | false;
  link?: any;
  linkURL?: any;
  concat?: true | false;
}
export const Reference = ({
  label,
  linked,
  link,
  linkURL,
  concat,
  ...props
}: Props) => {
  return (
    <div className="flex w-full pt-1">
      {linked ? (
        <a className="text-nafl-sponge-700-700">
          {concat
            ? link.substring(0, 4) +
              "..." +
              link.substring(link.length - 6, link.length)
            : link}
        </a>
      ) : (
        <span className="text-nafl-charcoal-800 text-body-base">{link}</span>
      )}
    </div>
  );
};
