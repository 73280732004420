import { useState, useEffect } from "react";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { useAccount } from "wagmi";
import * as Sentry from "@sentry/react";
import { ethers } from "ethers";

const getChain = () => {
  if (process.env.REACT_NETWORK === "goerli") {
    return EvmChain.GOERLI;
  }
  return EvmChain.ETHEREUM;
};

export function useGetStakedNFTsOffChain() {
  const { address } = useAccount();
  const [transactions, setTransactions] = useState([]);
  const chain = getChain();
  const contractAddress = process.env.REACT_STAKING_CONTRACT_LEGACY_ADDRESS || "";
  const [isLoading, setIsLoading] = useState(false);
  const [stakedNFTs, setStakedNFTs] = useState([]);

  const get_currenty_staked_nfts = (transaction_data) => {
    let nfts = {}
    const moment = require("moment-timezone");
    transaction_data.forEach((tx) => {
        if (tx["function"] == "stake") {
            let unix = moment(tx['block_timestamp']).unix();
            nfts[tx["value"][0]] = {
                "nftId": tx["value"][0],
                "stakedSince": unix,
                "stakingPeriod": tx["value"][1],
            }
        } else if (tx["function"] == "unstake") {
            delete nfts[tx["value"][0]]
        }
    })
    setStakedNFTs(Object.values(nfts))
  }


  useEffect(() => {
    let filtered_transaction_data = [];
        
    for (let index in transactions) {
        const transaction = transactions[index];
        // staked function
        var parameter_types = ["uint16", "uint8"]
        var functionName = "stake"
        
        if (transaction.decoded_call) {
            if (transaction.decoded_call["label"] == "unstake") {
                parameter_types = ["uint16"]
                functionName = "unstake"
            } 
        }
        try {
            let value = ethers.utils.defaultAbiCoder.decode(
                parameter_types,
                ethers.utils.hexDataSlice(transaction.input, 4)
            )
            if (transaction.from_address.toLowerCase() == address.toLowerCase()) {
                filtered_transaction_data.push({
                    "block_timestamp": transaction.block_timestamp,
                    "function": functionName,
                    "value": value,
                })
            }
        } catch (error) {
            // method is not stake.
        }
    }

    if (filtered_transaction_data.length > 0) {
        // we reverse the list because we loop throught the transaction from the beginning
        get_currenty_staked_nfts(filtered_transaction_data.reverse())
    }
    }, [transactions]);
    

 async function callMoralis(cursor) {
    const response = await Moralis.EvmApi.transaction
        .getWalletTransactionsVerbose({
            address: contractAddress,
            chain,
            limit: 100,
            cursor: cursor,
          })
          .catch((error) => {
            Sentry.captureException(error);
            if (error.details.status == 429) {
                setTimeout(()=> {
                return callMoralis(cursor)
                } ,1000);    
            } else {
                Sentry.captureException(error);
            }
          });
    return response
}

  async function getTransactions() {
    let cursor = null;
    var transaction_list = []
    do {
      const response = await callMoralis(cursor);
      if (!response) {
        break;
      }
      let jsonResponse = response.toJSON()
      for (let index in jsonResponse["result"]) {
        if (jsonResponse["result"][index]["receipt_status"] == 1) {
          transaction_list.push(jsonResponse["result"][index])
        }
      }
      cursor = jsonResponse["cursor"];
    } while (cursor != "" && cursor != null);
    setTransactions(transaction_list);
  }

  useEffect(() => {
     getTransactions();
  }, [address]);

  return [stakedNFTs, isLoading];
}
