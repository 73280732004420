import { CardPromo } from "./Card/card.promo";
import { CardInfo } from "./Card/card.info";
import { CardStake } from "./Card/card.staked";
import { CardConnect } from "./Card/card.connect";
import { useEffect, useState } from "react";
import { useGetAddressStakedNFTs } from "../hooks/useGetAddressStakedNFTs";
import { useAccount } from "wagmi";
import { useGetAddressNFTs } from "../hooks/useGetAddressNFTs";
import "../app.css";
import { useGetStakedNFTsOffChain } from "../hooks/useGetStakedNFTsOffChain";

const stakingLink = "https://bit.ly/3X7y5is";
//Stake period defaults to 0 = (12 Months) and date defaults to today whether staked or not.
//When staked stakeSatus change to true and revised input from usr for stakedate, stakeperiod
export default function Content() {
  const { isConnected } = useAccount();
  const [unstakedNFTs, isLoading] = useGetAddressNFTs();
  const [legacyStakedNFTs] = useGetStakedNFTsOffChain();
  const [stakedNFTs] = useGetAddressStakedNFTs();

  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10 w-full items-center px-4">
      <div className="w-80 m-auto">
        <CardInfo />
      </div>
      {legacyStakedNFTs?.map((stakedNFT: any) => (
        stakedNFT.nftId != 0 ?
        <div className="w-80 m-auto">
          <CardStake
            collection="Omnipotent"
            id={stakedNFT.nftId}
            stakingContractAddress={process.env.REACT_STAKING_CONTRACT_LEGACY_ADDRESS as `0x${string}`}
            stakingLink={stakingLink}
            stakeDate={stakedNFT.stakedSince}
            stakePeriod={stakedNFT.stakingPeriod}
            isStaked={true}
          />
        </div> : <></>
      ))}
      {stakedNFTs?.map((stakedNFT: any) => (
        stakedNFT.nftId != 0 ?
        <div className="w-80 m-auto">
          <CardStake
            collection="Omnipotent"
            id={stakedNFT.nftId}
            stakingContractAddress={process.env.REACT_STAKING_CONTRACT_ADDRESS as `0x${string}`}
            stakingLink={stakingLink}
            stakeDate={stakedNFT.stakedSince.toNumber()}
            stakePeriod={stakedNFT.stakingPeriod}
            isStaked={true}
          />
        </div> : <></>
      ))}
      {isLoading && isConnected ? (
        <div className="text-center">
          <div className="lds-ring">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {unstakedNFTs?.map((unstakedNFT: any) => (
        <div className="w-80 m-auto">
          <CardStake
            collection="Omnipotent"
            id={unstakedNFT.token_id}
            stakingContractAddress={process.env.REACT_STAKING_CONTRACT_ADDRESS as `0x${string}`}
            stakePeriod={3}
            stakeDate={null}
            stakingLink={stakingLink}
            isStaked={false}
          />
        </div>
      ))}
    </div>
  );
}
