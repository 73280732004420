import { useAccount, useContractRead } from "wagmi";
import stakingABI from "../../contracts/FoundersKeyStaking.json";

export function useGetAddressStakedNFTs() {
  const { address: userAddress } = useAccount();
  const {
    data: stakedNFTs,
    isError,
    isLoading,
  } = useContractRead({
    address: process.env.REACT_STAKING_CONTRACT_ADDRESS,
    abi: stakingABI.abi,
    functionName: "getStakedNFTInfos",
    args: [userAddress],
  });

  return [stakedNFTs, isError, isLoading];
}
