import { ReactNode, HTMLAttributes, useState, useEffect } from "react";
import { InjectedConnector } from "wagmi/connectors/injected";
import { useAccount, useConnect } from "wagmi";
import { BiTimer } from "react-icons/bi";
import { BiTimer } from "react-icons/bi";
import { Button, Dropdown } from "naffles-design-system";

interface Props extends HTMLAttributes<HTMLDivElement> {
  startDate: any;
}
export const CardInfo = ({ startDate, ...props }: Props) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const { isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });

  return (
    <div
      className="
      relative h-[28rem] w-80 rounded-3xl bg-nafl-sponge-500 shadow-xl overflow-hidden
      transition-all p-2 relative
      before:content[''] before:absolute before:h-8 before:w-8 
      before:bg-nafl-charcoal-600 before:rounded-full before:top-[-1rem] before:left-[calc(50%-1rem)]"
      {...props}
    >
      <div className="h-auto flex w-full space-x-4 pt-4 items-center">
        <div className="w-32">
          <BiTimer className="text-[5.6rem] text-nafl-charcoal-800" />
        </div>
        <h3 className="text-bold-sm leading-5 uppercase text-nafl-charcoal-800">
          When you unstake you will lose{" "}
          <span className="text-nafl-jade-600">all</span> your unclaimed rewards
        </h3>
      </div>
      <hr className="my-4 border-nafl-charcoal-500" />
      <div className="text-bold-sm leading-5 uppercase text-nafl-charcoal-500 px-2 py-2">
        <p>STACKED Bonuses include:</p>
        <ul className="list-disc pl-5 mt-2">
          <li>10 free ENTRY tickets</li>
          <li>+10% FEE discount</li>
        </ul>
      </div>
      <div className="absolute b-0">
        <hr className="my-4 border-nafl-charcoal-500" />
        <h3 className="text-bold-base leading-5 uppercase text-nafl-charcoal-800 mb-4">
          Time Remaining
        </h3>
        <div className="flex space-x-5 px-2">
          <div>
            <div className="bg-nafl-sponge-600 h-12 w-12 rounded-lg text-center py-3 mx-auto mb-1">
              <span className="text-[1.5rem] leading-5 uppercase text-nafl-charcoal-800 font-bold">
                {days}
              </span>
            </div>
            <span className="text-bold-sm leading-5 uppercase text-nafl-charcoal-800">
              Days
            </span>
          </div>
          <div>
            <div className="bg-nafl-sponge-600 h-12 w-12 rounded-lg text-center py-3 mx-auto mb-1">
              <span className="text-[1.5rem] leading-5 uppercase text-nafl-charcoal-800 font-bold">
                {hours}
              </span>
            </div>
            <span className="text-bold-sm leading-5 uppercase text-nafl-charcoal-800">
              Hours
            </span>
          </div>
          <div>
            <div className="bg-nafl-sponge-600 h-12 w-12 rounded-lg text-center py-3 mx-auto mb-1">
              <span className="text-[1.5rem] leading-5 uppercase text-nafl-charcoal-800 font-bold">
                {minutes}
              </span>
            </div>
            <span className="text-bold-sm leading-5 uppercase text-nafl-charcoal-800">
              Minutes
            </span>
          </div>
          <div>
            <div className="bg-nafl-sponge-600 h-12 w-12 rounded-lg text-center py-3 mx-auto mb-1">
              <span className="text-[1.5rem] leading-5 uppercase text-nafl-charcoal-800 font-bold">
                {seconds}
              </span>
            </div>
            <span className="text-bold-sm leading-5 uppercase text-nafl-charcoal-800 ">
              Seconds
            </span>
          </div>
        </div>
      </div>
      {!isConnected && (
        <div className="flex flex-col space-y-4 px-2">
          <div className="h-18 absolute bottom-6 w-[18rem]">
            <Button
              label="Connect wallet"
              onClick={() => {
                connect();
              }}
              status="enabled"
              size="base"
              variant="secondary"
              width="span"
            />
          </div>
        </div>
      )}
    </div>
  );
};
