import toast from "react-hot-toast";

export const showError = () => {
  toast.error("Something went wrong.", {
    style: {
      border: "1px solid ##c0392b",
      padding: "16px",
      color: "#2ecc71",
    },
    iconTheme: {
      primary: "#c0392b",
      secondary: "#f1c40f",
    },
  });
};
export const showSuccess = () => {
  toast.success("Successfully staked NFT.", {
    style: {
      border: "1px solid #2ecc71",
      padding: "16px",
      color: "#2ecc71",
    },
    iconTheme: {
      primary: "#2ecc71",
      secondary: "#f1c40f",
    },
  });
};
export const showSuccessUnstake = () => {
  toast.success("Successfully unstaked NFT.", {
    style: {
      border: "1px solid #2ecc71",
      padding: "16px",
      color: "#2ecc71",
    },
    iconTheme: {
      primary: "#2ecc71",
      secondary: "#f1c40f",
    },
  });
};
