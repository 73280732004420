import { FaDiscord, FaTwitter, FaWallet } from "react-icons/fa";
import BrandIcon from "../static/nafl-icon-black.svg";
import { InjectedConnector } from "wagmi/connectors/injected";
import { Reference } from "./atoms/reference";
import { useAccount, useConnect } from "wagmi";

const TWITTER = "https://twitter.com/nafflesofficial";
const DISCORD = "https://discord.gg/naffles";

export function Header() {
  const { address } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });

  const handleConnect = () => {
    if (!address) {
      connect();
    }
  };

  return (
    <div className="fixed w-full h-20 t-0 l-0 bg-gradient-to-b  from-nafl-charcoal-600 z-40 mx-auto">
      <div className="relative bg-nafl-sponge-500 rounded-xl pt-2 h-16 mt-4 mx-auto px-2 py-1 w-[calc(100%-2rem)]">
        <div className="absolute bg-nafl-charcoal-600 rounded-full h-8 w-8 top-[-1rem] left-[calc(50vw-1rem)]" />
        <div className="flex flex-row items-center py-2 px-4 justify-between">
          <div className="flex lg:flex-row lg:space-x-8 items-center space-y-4 lg:space-y-0">
            <span className="relative w-12 h-6">
              <img src={BrandIcon} alt="Icon" />
            </span>
            <div className="hidden lg:flex space-x-4 px-2text-nafl-charcoal-800">
              <a href={TWITTER} target="_blank">
                <FaTwitter className="hover:text-nafl-sponge-700 transition-colors ease-out duration-150 cursor-pointer text-body-xl" />
              </a>
              <a href={DISCORD} target="_blank">
                <FaDiscord className="hover:text-nafl-sponge-700 transition-colors ease-out duration-150 cursor-pointer text-body-xl" />
              </a>
            </div>
          </div>
          <div
            onClick={() => handleConnect()}
            className="cursor-pointer flex items-center space-x-4 text-nafl-grey-900"
          >
            {address ? (
              <Reference
                label="address"
                concat={true}
                linkURL=""
                link={address}
                linked={true}
              />
            ) : (
              <span className="text-body-base text-nafl-charcoal-700">
                Not Connected
              </span>
            )}
            <div className="relative inline-block text-left">
              <div className="absolute left-[0.7rem] rounded-full border-2 border-nafl-sponge-500">
                {address ? (
                  <div className="w-[0.6rem] h-[0.6rem] rounded-full bg-nafl-sys-done" />
                ) : (
                  <div className="w-[0.6rem] h-[0.6rem] rounded-full bg-nafl-sys-error" />
                )}
              </div>

              <FaWallet className="mt-1 transition-colors ease-out duration-150 hover:text-[#8a8013] cursor-pointer text-2xl t-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
