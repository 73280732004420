import { PropsWithChildren, ReactNode } from "react";
import { cva, VariantProps } from "class-variance-authority";

interface Props {
  formID: ReactNode | any;
  label: ReactNode;
  placeholder: ReactNode | any;
}

const InputStyles = cva("", {
  variants: {
    width: {
      span: "w-full",
      default: "w-56",
    },
  },
});

interface Props extends VariantProps<typeof InputStyles> {}

interface Increment extends PropsWithChildren {}

export const Increment = ({
  formID,
  width,
  label,
  placeholder,
  ...props
}: Props) => {
  return (
    <div className={InputStyles({ width })}>
      <div className="flex flex-col space-y-2" {...props}>
        <label className="text-body-xs text-nafl-sponge-500">{label}</label>
        <input
          className="grow bg-nafl-charcoal-600 text-body-base text-nafl-charcoal-100 border-[1px] rounded-lg p-2 bg-nafl-charcoal-600 border-nafl-sponge-500 focus:outline-dashed focus:outline-2 focus:outline-offset-2 focus:outline-nafl-sys-complete placeholder:text-nafl-charcoal-400 placeholder:text-body-base"
          type="number"
          id={formID}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
